import { extendTheme } from '@chakra-ui/react'
import '@fontsource/poppins'
import { accordionAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

// default base style from the Input theme
const baseStyle = definePartsStyle({
  container: defineStyle({
    boxShadow: 'sm',
    _focus: {
      boxShadow: 'outline',
    },
  }),
})

// Defining a custom variant called outline
const outline = definePartsStyle(() => {
  return {
    container: {
      borderRadius: '16px',
      bg: 'white',
      p: 6,
    },
    button: {
      p: 0,
      fontSize: 'md',
      fontWeight: 700,
      color: 'primary.100',
      bg: 'white',
      _hover: {
        color: 'primary.100',
        bg: 'white',
      },
      _focus: {
        color: 'primary.100',
        bg: 'white',
      },
      _active: {
        color: 'primary.100',
        bg: 'white',
      },
      _highlighted: {
        color: 'primary.100',
        bg: 'white',
      },
      _groupFocus: {
        color: 'primary.100',
        bg: 'white',
      },
    },
    panel: {
      fontSize: 'sm',
      px: 0,
      pt: 4,
      pb: 0,
    },
  }
})

const variants = {
  outline,
}

const size = {
  md: defineStyle({
    w: 5,
    h: 5,
  }),
}

const sizes = {
  md: definePartsStyle({
    icon: size.md,
  }),
}

const accordionTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
})

// This should be moved up in the order
const theme = {
  colors: {
    primary: {
      300: '#F5A776',
      500: '#EE6C1B',
      main: '#EE6C1B',
      700: '#8F4110',
    },
    secondary: {
      50: '#F8F6F2',
      200: '#E4D9CD',
      400: '#C9B49A',
      600: '#968167',
    },
    white: '#FFFFFF',
    success: '#6C9F53',
    error: '#C6372E',
    tertiary: {
      100: '#D8E1EC',
      800: '#182940',
    },
    body: '#182940',
    button: {
      50: '#EE6C1B',
      100: '#EE6C1B',
      200: '#EE6C1B',
      300: '#EE6C1B',
      400: '#EE6C1B',
      500: '#EE6C1B',
      600: '#EE6C1B',
      700: '#EE6C1B',
      800: '#EE6C1B',
      900: '#EE6C1B',
    },
    gray: {
      50: '#B6B4B1',
      100: '#71706E',
      400: '#434443',
      900: '#13171D',
    },
  },
  components: {
    Accordion: accordionTheme,
  },
  boxShadow: '0px 2px 3px 0px #C7A99B33',
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`,
  },
  styles: {
    global: {
      html: {
        scrollBehavior: 'smooth',
      },
      body: {
        bg: 'secondary.50',
        color: 'tertiary.800',
      },
    },
  },
}

export default extendTheme(theme)
